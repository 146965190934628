.selected-card {
  background-color: blue;
  color: white;
}

.btn-primary {
    /* Background color */
    background-color: #0026fe; /* Replace with your desired primary color */
    
    /* Text color */
    color: #ffffff; /* White text color */
    
    /* Border */
    border: 1px solid rgb(15, 15, 243); /* Border color same as background color */
    border-radius: 5px; /* Rounded corners */
    
    cursor: pointer;
}

.slideshow-height {
  min-height: 300px !important;
}

.active {
  background-color: black;
}

/* Custom styles for alert dialogs */
.custom-alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

  .bonus-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease-out;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .bonus-modal {
    background: linear-gradient(45deg, #FFD700, #FFA500);
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 0 30px rgba(255, 215, 0, 0.7);
    animation: modalAppear 0.5s ease-out;
  }

  @keyframes modalAppear {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }

  .congratulations-text {
    font-size: 3em;
    color: #FFFFFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    animation: textPop 0.5s ease-out 0.5s both;
  }

  .win-text {
    font-size: 1.5em;
    color: #FFFFFF;
    margin-bottom: 30px;
    animation: textPop 0.5s ease-out 0.7s both;
  }

  @keyframes textPop {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  .bonus-button {
    padding: 15px 30px;
    font-size: 1.2em;
    color: #FFD700;
    background-color: #800080;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    animation: buttonPulse 2s infinite;
  }

  .bonus-button:hover {
    background-color: #9400D3;
    transform: scale(1.05);
  }

  @keyframes buttonPulse {
    0% { box-shadow: 0 0 0 0 rgba(128, 0, 128, 0.7); }
    70% { box-shadow: 0 0 0 15px rgba(128, 0, 128, 0); }
    100% { box-shadow: 0 0 0 0 rgba(128, 0, 128, 0); }
  }

/* Other custom styles as needed */
@keyframes sparkle {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 5px #4CAF50, 0 0 10px #4CAF50, 0 0 15px #4CAF50;
  }
  50% {
    box-shadow: 0 0 10px #4CAF50, 0 0 20px #4CAF50, 0 0 30px #4CAF50;
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-2px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(2px);
  }
}

@keyframes borderPulse {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7), 0 0 0 0 rgba(255, 215, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0), 0 0 0 15px rgba(255, 215, 0, 0);
  }
}

@keyframes borderColorChange {
  0%, 100% {
    border-color: #4CAF50;
  }
  50% {
    border-color: #FFD700;
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}