.login-container {
  background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
  position: relative;
  overflow: hidden;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.8) 100%);
  z-index: 1;
}

.login-form-container {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 450px;
  padding: 2rem;
}

.glass-morphism {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.bingo-balls {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.bingo-ball {
  width: 40px;
  height: 40px;
  background: linear-gradient(145deg, #ffd700, #ffa500);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #000;
  animation: bounce 0.5s ease infinite alternate;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.bingo-ball:nth-child(1) { animation-delay: 0s; }
.bingo-ball:nth-child(2) { animation-delay: 0.1s; }
.bingo-ball:nth-child(3) { animation-delay: 0.2s; }
.bingo-ball:nth-child(4) { animation-delay: 0.3s; }
.bingo-ball:nth-child(5) { animation-delay: 0.4s; }

@keyframes bounce {
  from { transform: translateY(0); }
  to { transform: translateY(-10px); }
}

.form-control {
  border: 1px solid rgba(255,255,255,0.1);
}

.form-control:focus {
  background-color: rgba(255,255,255,0.1) !important;
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255,193,7,0.25);
  color: white !important;
}

.form-control::placeholder {
  color: rgba(255,255,255,0.5);
} 